import { Table } from 'antd';
import moment from 'moment';
import { applicationUrl, capitalize, formatMoney } from '../../../utils';
import TableLoading from '../../../components/TableLoading';
import useFetch from '../../../custom_hooks/useFetch';
import { debitNoteTypeColor } from '../../../data';
import Search from '../../../components/Search';
import StatementFilter from '../../statements/StatementFilter';
import ExportToPDFModal from '../../../components/ExportToPdfModal';
import { useDispatch, useSelector } from 'react-redux';
import useModalToggle from '../../../custom_hooks/useModalToggle';
import useDynamicFilter from '../../../custom_hooks/useDynamicFilter';
import { useEffect, useMemo, useState } from 'react';
import { clearFilters, fetchTransactionFilter } from '../../../features/filters/filterSlice';

const columns = [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (item) => <span>{moment(item).format('Do MMM YYYY')}</span>,
        sorter: (a, b) => a - b,
    },
    {
        title: 'Debit (KES)',
        render: (item) => {
            const { trxType, trxAmount } = item;
            if (trxType === 'DEBIT') {
                return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
            }
            return <span>-</span>;
        },
    },
    {
        title: 'Credit (KES)',
        render: (item) => {
            const { trxType, trxAmount } = item;
            if (trxType === 'CREDIT') {
                return <span>{formatMoney(Math.abs(trxAmount), 'KES')}</span>;
            }
            return <span>-</span>;
        },
    },
    {
        title: 'Ref no.',
        dataIndex: 'transId',
        render: (item) => <span>{item ? item : ' - '}</span>,
    },
    {
        title: 'Activity',
        dataIndex: 'trxSource',
        render: (item) => {
            const roleColor = debitNoteTypeColor.find((role) => String(role.label).toUpperCase() === String(item).toUpperCase())?.value || '#F6F6F6';

            return (
                <div
                    style={{
                        background: roleColor,
                    }}
                    className="flex w-full min-h-[1.8rem] h-auto items-center justify-center gap-[.5rem] px-[.5rem] py-[.25rem]"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                        <circle cx="2.66602" cy="2" r="2" fill="#212121" />
                    </svg>
                    <span>{capitalize(item?.split('_').join(' '))}</span>
                </div>
            );
        },
    },
];

const filterOptions = [
    {
        key: '0',
        category: 'Filter by type',
        options: ['All', 'DEBIT', 'CREDIT'],
    },
    {
        key: '1',
        category: 'Filter by activity',
        options: ['All', 'OUTSTANDING_BALANCE', 'ARREARS', 'CONTRIBUTION', 'WITHDRAWAL', 'TRANSFER', 'Penalty', 'Other'],
    },
];

export default function ActivityHistoryTransactionTable() {
    const dispatch = useDispatch();

    const { data, isLoading } = useFetch(`${applicationUrl().url}/api/v1/transactions/member/all`);

    const { members } = useSelector((state) => state.fetch);
    const { user } = useSelector((state) => state.auth);
    const { fiLoading, transactionFilters, filters } = useSelector((state) => state.filter);

    const [searchInput, setsearchInput] = useState('');
    const [filterView, setfilterView] = useState(false);

    const { open, handleCancel, handleOpen } = useModalToggle();

    let memberNo = members?.find((item) => item?.gpmUsrId === user?.jti)?.gpmNumber;

    const filterFns = useMemo(() => {
        const search = searchInput.toUpperCase();
        return [
            (item) => {
                if (!search) return item;
                const { trxAmount, createdAt, transId, trxSource } = item || {};
                return (
                    String(trxAmount).includes(search) ||
                    moment(createdAt).format('Do MMM YYYY').toUpperCase().includes(search) ||
                    String(transId).toUpperCase().includes(search) ||
                    capitalize(trxSource?.split('_').join(' ')).toUpperCase().includes(search)
                );
            },
        ];
    }, [searchInput]);

    const filteredData = useDynamicFilter(data?.content, filterFns);

    const handleSearch = (val) => setsearchInput(val);

    const handleApplyFilters = async (filters) => {
        const preparedFilters = {
            ...filters,
            filters: {
                ...filters?.filters,
                'Filter by activity': filters?.filters['Filter by activity'] || {},
                'Filter by type': filters?.filters['Filter by type'] || {},
            },
        };
        delete preparedFilters.filters?.dateRange;
        await dispatch(fetchTransactionFilter(preparedFilters));
        await setfilterView(true);
    };

    const handleClearFilters = () => {
        dispatch(clearFilters());
        setfilterView(false);
    }

    const hasFiltersApplied = Object.keys(filters || {}).length > 0;
    const noRecords = filterView && hasFiltersApplied && (!transactionFilters?.content || transactionFilters?.content?.length === 0);

    useEffect(() => {
        dispatch(clearFilters());
    }, []);

    useEffect(() => {}, [transactionFilters, filters, data]);

    if (isLoading || fiLoading) return <TableLoading />;

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-[1rem] w-full">
                    <div className="w-[13.625rem] hidden lg:flex">
                        <Search handleSearch={handleSearch} searchInput={searchInput} readOnly text="Search" />
                    </div>

                    <div className="w-[12.5rem] hidden lg:flex">
                        <StatementFilter disabled filterOptions={filterOptions} handleApplyFilters={handleApplyFilters} handleClearFilters={handleClearFilters} />
                    </div>
                </div>
                <div className="flex w-auto">
                    <button onClick={handleOpen} type="button" className="cstm-btn-2 !gap-[.25rem]">
                        <svg className="flex flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path
                                d="M11.65 18.45L15.35 11.25H12.5V6.5L8.675 13.75H11.65V18.45ZM12 22C10.6833 22 9.446 21.75 8.288 21.25C7.12933 20.75 6.125 20.075 5.275 19.225C4.425 18.375 3.75 17.3707 3.25 16.212C2.75 15.054 2.5 13.8167 2.5 12.5C2.5 11.1833 2.75 9.94567 3.25 8.787C3.75 7.629 4.425 6.625 5.275 5.775C6.125 4.925 7.12933 4.25 8.288 3.75C9.446 3.25 10.6833 3 12 3C13.3167 3 14.5543 3.25 15.713 3.75C16.871 4.25 17.875 4.925 18.725 5.775C19.575 6.625 20.25 7.629 20.75 8.787C21.25 9.94567 21.5 11.1833 21.5 12.5C21.5 13.8167 21.25 15.054 20.75 16.212C20.25 17.3707 19.575 18.375 18.725 19.225C17.875 20.075 16.871 20.75 15.713 21.25C14.5543 21.75 13.3167 22 12 22ZM12 20.5C14.2333 20.5 16.125 19.725 17.675 18.175C19.225 16.625 20 14.7333 20 12.5C20 10.2667 19.225 8.375 17.675 6.825C16.125 5.275 14.2333 4.5 12 4.5C9.76667 4.5 7.875 5.275 6.325 6.825C4.775 8.375 4 10.2667 4 12.5C4 14.7333 4.775 16.625 6.325 18.175C7.875 19.725 9.76667 20.5 12 20.5Z"
                                fill="#FFCD47"
                            />
                        </svg>
                        <span className="flex flex-shrink-0">Export to pdf</span>
                    </button>
                </div>
            </div>
            {isLoading || fiLoading ? (
                <TableLoading />
            ) : noRecords ? (
                <Table rowKey="trxId" className="mt-[1.5rem] !w-full" scroll={{ x: 1000 }} columns={columns} dataSource={[]} />
            ) : (
                <Table
                    rowKey="trxId"
                    className="mt-[1.5rem] !w-full"
                    pagination={{
                        defaultPageSize: 7,
                        hideOnSinglePage: true,
                        pageSizeOptions: [10, 20, 50, 100],
                    }}
                    scroll={{
                        x: 1200,
                    }}
                    columns={columns}
                    dataSource={transactionFilters?.content?.length ? transactionFilters.content : filteredData}
                />
            )}

            <ExportToPDFModal open={open} handleCancel={handleCancel} P_STATEMENT={'ACCOUNT_STATEMENT'} rptId={1} P_MEMBER_NO={memberNo} P_USER={user?.userName} />
        </>
    );
}
